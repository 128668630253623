export class SimpleRenderer {
  constructor(p5, config, skyManager) {
    this.p5 = p5
    this.config = config
    this.skyManager = skyManager
  }

  windowResized() { }

  draw() {
    this.p5.background(0)
    this.p5.noStroke()

    let bubbleSpacing = this.config.render.bubbleSpacing
    let bubbleRadius = this.config.render.bubbleRadius

    for (let x = -bubbleRadius; x <= this.p5.width + bubbleRadius; x += bubbleSpacing) {
      for (let y = -bubbleRadius; y <= this.p5.height + bubbleRadius; y += bubbleSpacing) {
        let [r, g, b] = this.skyManager.coordToColor(x, y)

        this.p5.fill(r, g, b)
        this.p5.circle(x, y, bubbleRadius * 2)
      }
    }
    // for (let locationIdx = 0; locationIdx < clockLocations.length; locationIdx++) {
    //   let x = locationIdx / clockLocations.length * width
    //   let w = 1 / clockLocations.length * width
    //   let pointSize = height/skyCalcs[locationIdx].resolution.y * 2.4
    //   skyCalcs[locationIdx].simpleRender(x, 0, w, height+pointSize, pointSize, 100) 
    // }
  }
}