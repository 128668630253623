<template>
  <div>
    <n-form inline>
      <n-form-item label="bubbleSpacing">
        <n-input-number :value="modelValue.bubbleSpacing" @update:value="emitUpdate('bubbleSpacing', $event)" :show-button="false" />
      </n-form-item>
      <n-form-item label="bubbleRadius">
        <n-input-number :value="modelValue.bubbleRadius" @update:value="emitUpdate('bubbleRadius', $event)" :show-button="false" />
      </n-form-item>
    </n-form>
  </div>
</template>

<script>
import {deepClone} from "../util.js"

export default {
  name: 'SimpleRendererConfig',
  props: ["modelValue"],
  emits: ["update:modelValue"],
  mounted() {
    // Set default values
    let val = Object.assign({
      bubbleSpacing: 40,
      bubbleRadius: 40,
    }, this.modelValue)
    this.$emit("update:modelValue", val)
  },
  methods: {
    emitUpdate(key, event) {
      let val = deepClone(this.modelValue)
      val[key] = event
      this.$emit("update:modelValue", val)
    }
  }
}
</script>

<style>

</style>