<template>
  <div>
    <n-form inline>
      <n-form-item label="Batch Render (recommended)">
        <n-switch :value="modelValue.batchRender" @update:value="emitUpdate('batchRender', $event)" />
      </n-form-item>
      <n-form-item label="Render Optimize (not recommended 🙃)">
        <n-switch :value="modelValue.renderOptimize" @update:value="emitUpdate('renderOptimize', $event)" />
      </n-form-item>
      <n-form-item label="Grid Size">
        <n-input-number :value="modelValue.cellGeneration?.gridSize" @update:value="emitCGUpdate('gridSize', $event)" :show-button="false" />
      </n-form-item>
      <n-form-item label="Density">
        <n-input-number :value="modelValue.cellGeneration?.density" @update:value="emitCGUpdate('density', $event)" :show-button="false" />
      </n-form-item>
    </n-form>
  </div>
</template>

<script>
import {deepClone} from "../util.js"

export default {
  name: 'VoronoiRendererConfig',
  props: ["modelValue"],
  emits: ["update:modelValue"],
  mounted() {
    // Set default values
    let modelVal = deepClone(this.modelValue)
    let val = Object.assign({
      batchRender: true,
      renderOptimize: false,
      cellGeneration: {}
    }, modelVal)

    val.cellGeneration = Object.assign({
      gridSize: 50,
      density: 0.0025,
    }, val.cellGeneration)

    this.$emit("update:modelValue", val)
  },
  methods: {
    emitUpdate(key, event) {
      let val = deepClone(this.modelValue)
      val[key] = event
      this.$emit("update:modelValue", val)
    },
    emitCGUpdate(key, event) {
      let val = deepClone(this.modelValue)
      val.cellGeneration[key] = event
      this.$emit("update:modelValue", val)
    }
  }
}
</script>

<style>

</style>